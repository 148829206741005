/** @jsxImportSource @emotion/react */

import {
  Column,
  Container,
  Row,
  Typography,
  theme,
} from "@a_team/ui-components";
import { toDiscoverSearchResultsLocation } from "Locations";
import FeatureFlagComponent from "components/FeatureFlag";
import { useGlobalModals } from "components/GlobalModals";
import Layout from "components/Layout";
import DefaultSidebar from "components/Layout/Sidebar";
import { StickyHeader } from "components/StickyHeader";
import { Flags } from "configs/featureFlags";
import { truncate } from "helpers/strings";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { useIsMobile } from "hooks/useIsMobile";
import { useMinBreakpoint } from "hooks/useMinBreakpoint";
import useQueryParams from "hooks/useQueryParams";
import { useBuildersSemanticSearch } from "queries/discover/useBuildersSemanticSearch";
import { useIsEnterprise } from "queries/discover/useIsEnterprise";
import {
  CSSProperties,
  FC,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useNavigate } from "react-router-dom-v5-compat";
import { Advisor } from "views/Browse/Search/Advisor";
import BuilderResult from "views/Browse/Search/BuilderResult";
import { Builders } from "views/Browse/Search/Builders";
import { FakeSearchEntry } from "views/Browse/Search/FakeSearchEntry";
import { Filters } from "views/Browse/Search/Filters";
import { HourlyRateFilter } from "views/Browse/Search/HourlyRateFilter";
import { NoResults } from "views/Browse/Search/NoResult";
import { SearchContextProvider } from "views/Browse/Search/SearchContextProvider";
import SearchInput from "views/Browse/Search/SearchInput";
import {
  SearchFilters,
  SearchFiltersAvailability,
  SearchFiltersYearsExperience,
} from "views/Browse/Search/types";
import { useSearchCSSRules } from "views/Browse/Search/useSearchCSSRules";
import SearchModal from "views/Browse/SearchModal";
import StartHiringBanner from "views/Browse/StartHiringBanner";

let defaultFilters: SearchFilters = {
  availability: SearchFiltersAvailability.Now,
  countries: [],
  yearsExperience: SearchFiltersYearsExperience.All,
};

const SearchResultsV2: FC = () => {
  const { toggleSchedulingModal } = useGlobalModals();
  const [pageLoaded, setPageLoaded] = useState(false);
  const isMobile = useIsMobile();
  const { flagEnabled: directInterviewRatesFlag } = useFeatureFlag(
    Flags.DirectInterviewBookingHourlyRates
  );
  const isEnterprise = useIsEnterprise();
  const showRates = isEnterprise === false && directInterviewRatesFlag;
  const query = useQueryParams<{ query: string }>();
  const [filters, setFilters] = useState(defaultFilters);
  const searchVal = query.query || "";
  const {
    isLoading,
    data,
    isError: errorSearching,
  } = useBuildersSemanticSearch({
    filters,
    query: searchVal,
    source: "browse",
  });

  const hasBuilders = data?.users?.length ? true : false;
  const styles = useSearchCSSRules({
    pageLoaded,
    resultsLoaded: !isLoading,
    hasBuilders,
  });

  const isDesktop = useMinBreakpoint("sm");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const navigate = useNavigate();

  const totalBuilders = 10;
  const handleSearch = useCallback(
    (value: string) => {
      navigate(toDiscoverSearchResultsLocation(value));
    },
    [searchVal]
  );
  const onFiltersChange = (newFilters: Partial<SearchFilters>) => {
    setFilters({
      ...filters,
      ...newFilters,
    });
  };

  useLayoutEffect(() => {
    setPageLoaded(true);
    return () => setPageLoaded(false);
  }, []);

  const hourlyRateFilters = useMemo(() => {
    return (
      <FeatureFlagComponent on name={Flags.DirectInterviewBooking}>
        {showRates && (
          <div css={styles.hourlyRateFiltersContainer}>
            <HourlyRateFilter
              minRate={filters.minRate}
              maxRate={filters.maxRate}
              onChange={onFiltersChange}
            />
          </div>
        )}
      </FeatureFlagComponent>
    );
  }, [filters, onFiltersChange, showRates]);

  return (
    <SearchContextProvider userSearchId={data?.userSearchId}>
      <Layout
        ctaBlock={isDesktop && <StickyHeader cta="Start hiring" />}
        fullWidth={true}
        hideBackButton={isDesktop}
        sidebar={isDesktop && <DefaultSidebar />}
        solidOnScroll={true}
        pageTitle={totalBuilders ? `${totalBuilders} builders` : `No results`}
        headerProps={{
          style: styles.header as CSSProperties,
        }}
        headerMainBlock={
          isDesktop ? (
            <SearchInput
              loading={isLoading}
              onChange={handleSearch}
              value={searchVal}
            />
          ) : (
            <FakeSearchEntry
              query={searchVal}
              setIsSearchOpen={setIsSearchOpen}
            />
          )
        }
      >
        <SkeletonTheme baseColor={theme.colors.Grey[200]} borderRadius={0}>
          <Container css={styles.main}>
            <Row style={{ zIndex: 2, position: "relative" }}>
              <Column sm={9}>
                <main css={styles.wrapper}>
                  <div css={styles.title}>
                    {isLoading && (
                      <Skeleton style={{ maxWidth: 300, height: 38 }} />
                    )}
                    {!isLoading && hasBuilders && (
                      <Typography
                        variant="textExtraLarge"
                        size={isDesktop ? 30 : 24}
                        weight={isDesktop ? "regular" : "bold"}
                        component="h1"
                        css={styles.waitForLoaded}
                      >
                        {`Results for "${truncate(
                          searchVal,
                          isDesktop ? 30 : 42
                        )}"`}
                      </Typography>
                    )}
                  </div>
                  <Filters
                    containerStyle={styles.filterContainer}
                    filters={filters}
                    onChange={onFiltersChange}
                  />
                  {isMobile && hourlyRateFilters}
                </main>
              </Column>
            </Row>
            <Row style={{ zIndex: 1, position: "relative" }}>
              <Column sm={9}>
                <main css={styles.container}>
                  {isLoading && (
                    <>
                      <BuilderResult />
                      <BuilderResult />
                      <BuilderResult divider={false} />
                    </>
                  )}
                  <div css={styles.waitForLoaded}>
                    {!isLoading && !hasBuilders && (
                      <div css={styles.noResults}>
                        <NoResults searchTerm={searchVal} />
                      </div>
                    )}
                    {errorSearching && (
                      <Typography
                        variant="textMedium"
                        weight="bold"
                        component="p"
                        color="Red@600"
                      >
                        Something went wrong. Try again later.
                      </Typography>
                    )}
                    {!isLoading && hasBuilders && (
                      <Builders
                        role={data?.meta.inferredRole}
                        builders={data?.users || []}
                      />
                    )}
                    {!isLoading && (
                      <Advisor
                        handleScheduleCall={() => toggleSchedulingModal(true)}
                      />
                    )}
                  </div>
                </main>
              </Column>
              <Column
                sm={3}
                css={styles.rightColumn}
                style={{ position: "sticky" }} // `Col` has position: relative set on style prop
              >
                {!isMobile && hourlyRateFilters}
                <StartHiringBanner
                  containerStyle={styles.startHiringStyle as CSSProperties}
                />
              </Column>
            </Row>
          </Container>
        </SkeletonTheme>

        <SearchModal
          isOpen={isSearchOpen}
          onClose={() => setIsSearchOpen(false)}
          containerStyle={{
            display: isSearchOpen && !isDesktop ? "flex" : "none",
            position: "fixed",
            top: 0,
            left: 0,
            background: "#fff",
            height: "100%",
            width: "100%",
            zIndex: 100,
          }}
        />
      </Layout>
    </SearchContextProvider>
  );
};

export default SearchResultsV2;
