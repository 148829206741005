import {
  createQueryKeys,
  mergeQueryKeys,
} from "@lukemorales/query-key-factory";
import { KeySchema } from "hooks/useQueryKeyAuth";

const accountKeys = createQueryKeys("accounts", {
  add: (specificity: KeySchema) => [specificity],
  aggregate: (specificity: KeySchema) => [specificity],
  collaborators: (specificity: KeySchema) => [specificity],
  detail: (specificity: KeySchema) => [specificity],
  list: (specificity: KeySchema) => [specificity],
});

const contractsKeys = createQueryKeys("contracts", {
  list: (specificity: KeySchema) => [specificity],
  platformAgreements: (specificity: KeySchema) => [specificity],
});

const discoverKeys = createQueryKeys("discover", {
  architects: (specificity: KeySchema) => [specificity],
  browse: (specificity: KeySchema) => [specificity],
  builderLists: (specificity: KeySchema) => [specificity],
  builders: (specificity: KeySchema) => [specificity],
  clientSignals: (specificity: KeySchema) => [specificity],
  shortlist: (specificity: KeySchema) => [specificity],
  starBuilder: (specificity: KeySchema) => [specificity],
  trackBuilderImpression: (specificity: KeySchema) => [specificity],
  unstarBuilder: (specificity: KeySchema) => [specificity],
  searchBuilders: (specificity: KeySchema) => [specificity],
  semanticBuildersSearch: (specificity: KeySchema) => [specificity],
  searchAutoComplete: (specificity: KeySchema) => [specificity],
  searchExtract: (specificity: KeySchema) => [specificity],
  personalizedFeeds: (specificity: KeySchema) => [specificity],
  feed: (specificity: KeySchema) => [specificity],
  feeds: (specificity: KeySchema) => [specificity],
});

const featureFlags = createQueryKeys("featureFlags", {
  list: (specificity: KeySchema) => [specificity],
  workspaceList: (specificity: KeySchema) => [specificity],
});

const invoicesKeys = createQueryKeys("invoices", {
  list: (specificity: KeySchema) => [specificity],
});

const talentIndustries = createQueryKeys("talentIndustries", {
  list: (specificity: KeySchema) => [specificity],
});

const talentSkillsKeys = createQueryKeys("talentSkills", {
  listExtended: (specificity: KeySchema) => [specificity],
  relevantSkills: (specificity: KeySchema) => [specificity],
});

const talentSpecializationsKeys = createQueryKeys("talentSpecializations", {
  list: (specificity: KeySchema) => [specificity],
});

const experiencesKeys = createQueryKeys("experiences", {
  experiencesByUserId: (specificity: KeySchema) => [specificity],
});

const usersKeys = createQueryKeys("users", {
  userProfileByUserId: (specificity: KeySchema) => [specificity],
  share: (specificity: KeySchema) => [specificity],
  invitation: (specificity: KeySchema) => [specificity],
  enrichment: (specificity: KeySchema) => [specificity],
});

const talentCategoriesKeys = createQueryKeys("talentCategories", {
  list: (specificity: KeySchema) => [specificity],
});

const teamExamplesKeys = createQueryKeys("teamExamples", {
  find: (specificity: KeySchema) => [specificity],
  list: (specificity: KeySchema) => [specificity],
});

const paymentCyclesKeys = createQueryKeys("paymentCycles", {
  list: (specificity: KeySchema) => [specificity],
  detail: (specificity: KeySchema) => [specificity],
});

const timesheetInitiativesKeys = createQueryKeys("timesheetInitiatives", {
  list: (specificity: KeySchema) => [specificity],
});

const workspaceKeys = createQueryKeys("workspace", {
  detail: (specificity: KeySchema) => [specificity],
});

const billingInfoKeys = createQueryKeys("billingInfo", {
  detail: (specificity: KeySchema) => [specificity],
});

const adminKeys = createQueryKeys("admin", {
  bdAdmins: (specificity: KeySchema) => [specificity],
  getAccountDuplicate: (specificity: KeySchema) => [specificity],
});

const paymentMethodsKeys = createQueryKeys("paymentMethods", {
  list: (specificity: KeySchema) => [specificity],
});

const hubspotKeys = createQueryKeys("hubspot", {
  dealById: (specificity: KeySchema) => [specificity],
});

const interviewKeys = createQueryKeys("interview", {
  list: (specificity: KeySchema) => [specificity],
  transcripts: (specificity: KeySchema) => [specificity],
});

const gongKeys = createQueryKeys("gong", {
  callById: (specificity: KeySchema) => [specificity],
  generated: (specificity: KeySchema) => [specificity],
});

const roleCategoriesKeys = createQueryKeys("roleCategories", {
  list: (specificity: KeySchema) => [specificity],
});

const rolesKeys = createQueryKeys("roles", {
  get: (specificity: KeySchema) => [specificity],
});

const searchServiceKeys = createQueryKeys("searchService", {
  allBuilderCount: (specificity: KeySchema) => [specificity],
  targetedBuilderCount: (specificity: KeySchema) => [specificity],
  targeterSuggestedBuilders: (specificity: KeySchema) => [specificity],
  listSkills: (specificity: KeySchema) => [specificity],
  listIndustries: (specificity: KeySchema) => [specificity],
  listCities: (specificity: KeySchema) => [specificity],
});

const solutionKeys = createQueryKeys("solutions", {
  list: (specificity: KeySchema) => [specificity],
});

const proposalsKeys = createQueryKeys("proposals", {
  detail: (specificity: KeySchema) => [specificity],
  list: (specificity: KeySchema) => [specificity],
});

const teamPulseKeys = createQueryKeys("teamPulse", {
  list: (specificity: KeySchema) => [specificity],
});

const teamProposalKeys = createQueryKeys("teamProposal", {
  get: (specificity: KeySchema) => [specificity],
});

const missionSpecsKeys = createQueryKeys("missionSpecs", {
  byId: (specificity: KeySchema) => [specificity],
  assets: (specificity: KeySchema) => [specificity],
  pending: (specificity: KeySchema) => [specificity],
  running: (specificity: KeySchema) => [specificity],
  titles: (specificity: KeySchema) => [specificity],
  requestedCount: (specificity: KeySchema) => [specificity],
});

const narrativeKeys = createQueryKeys("narratives", {
  narrativesSummaryForUserId: (specificity: KeySchema) => [specificity],
});

const nuxKeys = createQueryKeys("nux", {
  updateQuestionnaire: (specificity: KeySchema) => [specificity],
});

const assistantsKeys = createQueryKeys("assistants", {
  byUsername: (specificity: KeySchema) => [specificity],
  curator: (specificity: KeySchema) => [specificity],
  curation: (specificity: KeySchema) => [specificity],
  groupCuration: (specificity: KeySchema) => [specificity],
});

const userReviewsKeys = createQueryKeys("userReviews", {
  status: (specificity: KeySchema) => [specificity],
  byId: (specificity: KeySchema) => [specificity],
  list: (specificity: KeySchema) => [specificity],
  visibleUserReviewsByUserId: (specificity: KeySchema) => [specificity],
});

const schedulingKeys = createQueryKeys("scheduling", {
  createClientInterview: (specificity: KeySchema) => [specificity],
  clientInterviews: (specificity: KeySchema) => [specificity],
});

const similarBuildersKeys = createQueryKeys("similarBuilders", {
  similarBuildersForUserId: (specificity: KeySchema) => [specificity],
});

const ipSessionKeys = createQueryKeys("ipSession", {
  initialize: (specificity: KeySchema) => [specificity],
  getShortlistedBuilders: (specificity: KeySchema) => [specificity],
  shortlistBuilder: (specificity: KeySchema) => [specificity],
  unshortlistBuilder: (specificity: KeySchema) => [specificity],
  handoffTrigger: (specificity: KeySchema) => [specificity],
  onboardingState: (specificity: KeySchema) => [specificity],
  updateInterviewee: (specificity: KeySchema) => [specificity],
});

export const queryKeys = mergeQueryKeys(
  accountKeys,
  adminKeys,
  assistantsKeys,
  billingInfoKeys,
  contractsKeys,
  discoverKeys,
  experiencesKeys,
  featureFlags,
  gongKeys,
  hubspotKeys,
  interviewKeys,
  invoicesKeys,
  missionSpecsKeys,
  narrativeKeys,
  nuxKeys,
  paymentCyclesKeys,
  timesheetInitiativesKeys,
  paymentMethodsKeys,
  proposalsKeys,
  roleCategoriesKeys,
  rolesKeys,
  searchServiceKeys,
  solutionKeys,
  talentCategoriesKeys,
  talentIndustries,
  talentSkillsKeys,
  talentSpecializationsKeys,
  teamExamplesKeys,
  teamProposalKeys,
  teamPulseKeys,
  usersKeys,
  workspaceKeys,
  schedulingKeys,
  similarBuildersKeys,
  ipSessionKeys,
  userReviewsKeys
);

export default queryKeys;
