/** @jsxImportSource @emotion/react */

import { Icon, Textarea, Typography } from "@a_team/ui-components";
import { css } from "@emotion/react";
import tagManager, { GTMEvents } from "analytics/gtm";
import { useGlobalModals } from "components/GlobalModals";
import TrackButton from "components/TrackButton";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { FC, useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import SignupModal from "views/Register/SignupModal";
import sendIcon from "./assets/send.svg";

export interface InputProps {
  override?: string;
  onChange: (msg: string) => void;
  onInput?: (msg: string) => void;
  placeholder?: string;
  disabled?: boolean;
  size?: "small" | "regular";
  embedded?: boolean;
  initialValue?: string;
  className?: string;
  autoFocus?: boolean;
  limitReached?: boolean;
}

const useStyles = ({ size, embedded }: InputProps) => {
  return useCSSRulesWithTheme(({ colors }) => ({
    root: {
      paddingTop: 18,
      paddingRight: 50,
      borderRadius: 8,
      paddingLeft: 12,
      minHeight: 56,
      lineHeight: "20px",
      ...(size === "small"
        ? {
            borderRadius: 6,
            paddingTop: 10,
            minHeight: 40,
          }
        : {}),
      ...(embedded && {
        paddingLeft: 16,
        border: "none",
        borderRadius: "0 0 16px 16px",
        borderTop: `1px solid ${colors.Grey[300]}`,
      }),
      transition: "border-color 0.2s ease-out",
      "&:placeholder-shown": {
        backgroundColor: colors.Grey[100],
        borderColor: colors.Grey[200],
      },
      "&:focus": {
        backgroundColor: colors.Grey[0],
        borderColor: colors.Hannibal[600],
      },
    },
    send: {
      width: 32,
      height: 32,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 4,
      color: colors.Grey[400],
      position: "absolute",
      right: 12,
      bottom: 14,
      cursor: "pointer",
      transition: "all 0.3s ease-in-out",
      "& > div": {
        position: "relative",
        right: 1,
      },
      "& svg": {
        verticalAlign: "middle",
      },
      "&:hover": {
        color: colors.Grey[0],
        backgroundColor: colors.Hannibal[600],
      },
      ...(size === "small"
        ? {
            right: 8,
            bottom: 8,
            width: 24,
            height: 24,
          }
        : {}),
    } as const,
    disabled: {
      opacity: 0.5,
      pointerEvents: "none",
    } as const,
    active: {
      color: colors.Grey[0],
      backgroundColor: colors.Hannibal[600],
    },
    wrapper: {
      position: "relative",
    } as const,
    inputContainer: {
      width: "100%",
      position: "relative",
      "& textarea": {
        "&:focus": {
          background: colors.Grey[0],
        },
      },
    } as const,
    limitReachedContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",
      padding: 16,
      width: "100%",
      height: "100%",
      background: colors.Hannibal[600],
      border: `1px solid ${colors.Grey[200]}`,
      borderRadius: 7,
      boxShadow: "0px 7px 20px 0px rgba(0, 0, 0, 0.11)",
      gap: 4,
      "& > span": {
        display: "flex",
        alignItems: "center",
      },
    } as const,
    limitReachedButton: {
      display: "flex",
      alignItems: "center",
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 500,
      padding: 0,
      borderRadius: 8,
      "&&:hover": {
        background: "inherit",
      },
      "& > span": {
        fontSize: "inherit",
        display: "flex",
        alignItems: "center",
        gap: 2,
      },
    } as const,
    forwardIcon: {
      display: "inline-block",
      transform: "rotate(180deg)",
      "& svg": {
        width: "20px",
        height: "20px",
      },
    } as const,
  }));
};

const Input: FC<InputProps> = (props) => {
  const {
    onChange,
    override,
    placeholder,
    disabled,
    initialValue,
    onInput,
    className,
    autoFocus = true,
    limitReached,
  } = props;
  const styles = useStyles(props);
  const [message, setMessage] = useState(initialValue || "");

  const inputContainerRef = useRef<HTMLDivElement>(null);
  const { addModal, removeModal } = useGlobalModals();

  useEffect(() => {
    setMessage(initialValue || "");
  }, [initialValue]);

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    setMessage(value);
    onInput && onInput(value);
  };

  // handle enter key press
  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey && !e.metaKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  useEffect(() => {
    if (override) {
      setMessage(override || "");

      if (inputContainerRef.current) {
        const textareaElements =
          inputContainerRef.current.getElementsByTagName("textarea");
        if (textareaElements[0]) {
          textareaElements[0].focus();
        }
      }
    }
  }, [override]);

  const sendMessage = () => {
    if (disabled) {
      return;
    }
    onChange(message);
    setMessage("");
  };

  const handleFocus: React.FocusEventHandler<HTMLTextAreaElement> = (e) => {
    e.target.setSelectionRange(e.target.value.length, e.target.value.length);
  };

  const showSignupModal = () => {
    const modal = () => (
      <SignupModal
        onClose={() => {
          removeModal(modal);
        }}
        title="Sign up to curate another set of builders"
        isOpen
        onRegister={() => tagManager.push({ event: GTMEvents.TFAISignup })}
      />
    );
    addModal(modal);
  };

  return (
    <div css={styles.wrapper} className={className}>
      <div css={styles.inputContainer} ref={inputContainerRef}>
        <Textarea
          autoFocus={autoFocus}
          onFocus={handleFocus}
          css={styles.root}
          size="stretch"
          value={message}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          placeholder={placeholder}
        />
      </div>

      <div
        className="chat-input-send"
        css={css(
          styles.send,
          message && styles.active,
          disabled && styles.disabled
        )}
        onClick={sendMessage}
      >
        <ReactSVG src={sendIcon} alt="icon send" />
      </div>
      {limitReached && (
        <div css={styles.limitReachedContainer}>
          <Typography variant="textMedium" color="Grey@0">
            You’ve reached the search limit.
          </Typography>
          <TrackButton
            size="sm"
            onClick={showSignupModal}
            name="curator-search-limit-reached-sign-up"
            css={styles.limitReachedButton}
          >
            Please sign up to continue{" "}
            <Icon
              name="back"
              color="Grey@0"
              size="md"
              css={styles.forwardIcon}
              style={{ width: 20, height: 20 }}
            />
          </TrackButton>
        </div>
      )}
    </div>
  );
};

export default Input;
