/** @jsxImportSource @emotion/react */

import { CSSObject } from "@emotion/react";
import { SignupLocation, ToSigninLocation } from "Locations";
import { useGlobalModals } from "components/GlobalModals";
import { useProposalContext } from "components/Proposals/ReviewBuilders/ProposalContext";
import TrackButton from "components/TrackButton";
import config from "config";
import { zeroFrictionSignUpEnabled } from "configs/auth";
import { useCSSRulesWithTheme } from "hooks/useCSSRulesWithTheme";
import { useIsDirectSchedulingEnabled } from "hooks/useIsDirectSchedulingEnabled";
import { DateTime } from "luxon";
import { observer } from "mobx-react";
import { AdminReviewStatus, ProposalObject } from "models/Proposal";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useRootStore } from "store";
import { Flex } from "views/Builder/components/Flex";
import { PublicUntilBanner } from "views/Mission/Proposals/Native/PublicUntilBanner";
import { copyToClipboard } from "../../../../helpers/clipboard";
import { useUpdatePublicUntil } from "../../../../queries/proposals/useUpdatePublicUntil";
import useCheckAccessModal from "components/AccessModalProvider/useCheckAccessModal";
import NoAccessModalContent from "views/Mission/Proposals/Native/NoAccessModalContent";
import useCanAccessMission from "hooks/access/useCanAccessMission";

const MobileFooter = ({
  disabled,
  proposal,
  onAdminReviewProposalClick,
  onClientReviewProposalClick,
}: {
  disabled: boolean;
  onAdminReviewProposalClick?: (isApproval: boolean) => void;
  onClientReviewProposalClick?: () => void;
  proposal: ProposalObject;
}) => {
  const {
    userStore: { isAppInAdminMode, user: currentUser },
    uiStore: { setToast },
  } = useRootStore();
  const history = useHistory();

  const { mutate: updatePublicUntil } = useUpdatePublicUntil(
    (data: ProposalObject) => {
      if (data.publicUntil) {
        copyToClipboard(proposal.publicURL);
      }
      setToast({
        text: data.publicUntil
          ? "Proposal URL has been copied to the clipboard"
          : "Proposal has been switched to private mode",
        type: "success",
      });
    }
  );

  const directSchedulingEnabled = useIsDirectSchedulingEnabled();

  const { setInterviewModalMode, setSelectedInterviewee } =
    useProposalContext();
  const { toggleInterviewModal } = useGlobalModals();

  const hasMultipleBuilders =
    proposal.roles.length > 1 || proposal.roles[0].builders.length > 1;

  const showApprovalActions = useMemo(() => {
    return (
      isAppInAdminMode &&
      !proposal.isSampleProposal &&
      (proposal?.adminReview || {}).status !== AdminReviewStatus.Approved
    );
  }, [isAppInAdminMode, proposal]);

  const proposalId = proposal?.id;

  const { checkAndHandlePermission } = useCheckAccessModal();
  const hasMissionReadAccess = useCanAccessMission(
    "read",
    proposal?.missionSpecId
  );

  const styles = useCSSRulesWithTheme(({ colors }) => ({
    container: {
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: colors.Grey[0],
      padding: 15,
      boxShadow: "0px -1px 8px 0px rgba(0, 0, 0, 0.10)",
    } as CSSObject,
    button: {
      flex: 1,
    },
  }));

  const actions = useMemo(() => {
    if (!currentUser) {
      // Show login buttons
      return (
        <>
          <TrackButton
            name="Login"
            variant="secondary"
            onClick={() => history.push(ToSigninLocation())}
            css={styles.button}
          >
            Sign in
          </TrackButton>
          <TrackButton
            name="Signup"
            variant="main"
            onClick={() => {
              if (zeroFrictionSignUpEnabled) {
                history.push(SignupLocation);
              } else {
                window.location.href = config.REACT_APP_EXTERNAL_ONBOARDING_URL;
              }
            }}
            css={styles.button}
          >
            Create an account
          </TrackButton>
        </>
      );
    }

    if (proposal.isSampleProposal) {
      return null;
    }

    if (showApprovalActions) {
      return (
        <>
          <TrackButton
            css={styles.button}
            disabled={disabled}
            name="Admin approve proposal"
            onClick={() =>
              onAdminReviewProposalClick && onAdminReviewProposalClick(true)
            }
            size="md"
            variant="positive"
          >
            Approve
          </TrackButton>
          <TrackButton
            css={styles.button}
            disabled={disabled}
            eventProps={{ proposalId }}
            name="Admin reject proposal"
            onClick={() =>
              onAdminReviewProposalClick && onAdminReviewProposalClick(false)
            }
            size="md"
            variant="negative"
          >
            Hide
          </TrackButton>
        </>
      );
    }

    return (
      <>
        <TrackButton
          disabled={disabled}
          eventProps={{ proposalId }}
          name="Provide feedback"
          onClick={async () => {
            const hasPermission = await checkAndHandlePermission(
              {
                title: "Request access",
                content:
                  "You must be a member of this workspace to provide feedback.",
                forMissionSpecId: proposal?.missionSpecId,
              },
              () => hasMissionReadAccess
            );

            if (hasPermission) {
              onClientReviewProposalClick?.();
            }
          }}
          size="md"
          variant="secondary"
          style={{
            flex: 1,
          }}
        >
          Provide feedback
        </TrackButton>
        {directSchedulingEnabled && hasMultipleBuilders && (
          <TrackButton
            disabled={disabled}
            eventProps={{ proposalId }}
            name="Book interviews"
            onClick={async () => {
              const hasPermission = await checkAndHandlePermission(
                {
                  title: "Request access",
                  content: <NoAccessModalContent />,
                  forMissionSpecId: proposal?.missionSpecId,
                },
                () => hasMissionReadAccess
              );

              if (hasPermission) {
                setSelectedInterviewee(undefined);
                setInterviewModalMode("multiple");
                toggleInterviewModal(true);
              }
            }}
            size="md"
            css={styles.button}
          >
            Book interviews
          </TrackButton>
        )}
      </>
    );
  }, [
    hasMultipleBuilders,
    onClientReviewProposalClick,
    proposalId,
    showApprovalActions,
    proposal.isSampleProposal,
    styles,
  ]);

  return (
    <div css={styles.container}>
      {isAppInAdminMode &&
        proposal.adminReview?.status === AdminReviewStatus.Approved && (
          <PublicUntilBanner
            publicUntil={
              proposal.publicUntil
                ? DateTime.fromISO(
                    proposal.publicUntil.toLocaleString()
                  ).toJSDate()
                : undefined
            }
            onToggle={(sharePublicly) =>
              updatePublicUntil({ proposalId, sharePublicly })
            }
          />
        )}
      <Flex gap={8}>{actions}</Flex>
    </div>
  );
};

export default observer(MobileFooter);
