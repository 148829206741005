/** @jsxImportSource @emotion/react */

import { Toggle, Typography } from "@a_team/ui-components";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "hooks/useCSSRulesWithTheme";
import { observer } from "mobx-react";
import { useRootStore } from "store";

const getCSSRules: CSSRulesResolver = () => ({
  container: {
    display: "flex",
    alignItems: "flex-end",
    gap: 4,
  },
});

const ClientModeToggle = () => {
  const {
    userStore: { isAdmin, isAppInAdminMode, setIsAppInAdminMode },
  } = useRootStore();
  const styles = useCSSRulesWithTheme(getCSSRules);

  if (!isAdmin) {
    return null;
  }

  return (
    <div css={styles.container}>
      <Typography variant="textSmall">Client mode</Typography>
      <div>
        <Toggle
          checked={!isAppInAdminMode}
          onChange={() => setIsAppInAdminMode(!isAppInAdminMode)}
        />
      </div>
    </div>
  );
};

export default observer(ClientModeToggle);
